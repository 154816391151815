import React from 'react';

interface IProps {
  height: string | number,
  width: string | number,
}

export default function Logo(props: IProps) {
  return (
    <svg
      className="app-bar__logo__svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.0" 
      viewBox="0 0 533 533"
      width={ props.width } 
      height={ props.height }>
      <path d="M227 37.5c-41.9 7.4-80.9 25.6-112.7 52.9-33.3 28.5-55.1 60.8-68.1 100.7-7.7 23.5-11.5 47.5-11.5 70.9.3 73.2 25.3 130.8 76.1 175.4 37.4 32.8 76.1 50.6 125.7 57.8 11.2 1.6 46.4 1.6 60-.1 66.9-8.2 125-44.3 163-101 9.4-14.1 21.2-38 25.9-52.3 14.8-45.5 15.6-98.2 2-144.9C470.5 138.4 427.5 88.3 369 58.6c-15.7-8-28.8-12.9-45.6-17.2-20.5-5.2-32.2-6.4-59.2-6.3-20.2 0-25.7.4-37.2 2.4zm75.5 43.4c12 2.2 30.7 8.3 42.8 13.9C410.7 125.1 454 192.4 454 264.1c0 48.4-14.5 87.2-46.3 123.9-4.8 5.5-9.4 10-10.2 10-1.9 0-2-11.3-.5-30.6 3.9-48.1 17-90.8 39.4-128.8l4.6-7.7-12.3-7.9c-11.2-7.3-12.3-7.8-13.5-6.2-7.1 9.3-21.2 35.8-27.6 51.8-11.4 28.7-18.5 62.6-20.7 98.4-.6 10.2-1.3 19.1-1.5 19.8-.3 1-8.8 1.2-40.1 1l-39.8-.3-.3-60.3-.2-60.2h-39v121l-39.7-.2-39.8-.3-.3-4c-4.6-70-20.8-125-49-166.7l-1.8-2.8-11.4 6.6c-6.3 3.6-12 7-12.7 7.5-.9.9-.2 2.9 3.3 9.2 17.9 32.2 30.2 66.5 35.8 99.7 3.6 21.4 5.9 60 3.7 60-1.5 0-15.3-15.4-21.4-23.9-15.3-21.2-25.9-45.2-31.2-70.7-3.7-17.6-4.6-44-2-62.9 5.7-42.7 21.9-76.2 50.9-105 24.7-24.5 50-39.1 84.1-48.8 10.2-2.9 16.8-4.1 38-7.1 7.5-1.1 40.4.4 50 2.3z" />
      <path d="M259 217.3c-5.1 1.7-10.4 7.5-12 13.2-5.3 18.8 18.2 32.3 32 18.5 6.9-6.9 7.6-17.6 1.5-25.6-4.4-5.8-14.1-8.6-21.5-6.1z" />
    </svg>
  );
}
